import { usePostHog } from 'posthog-js/react'
import { SubscriptionPlan } from "./SubscriptionPlan"
import { MONTHLY_PRICE, SALE_PERCENTAGE, ANNUAL_MONTHLY_PRICE, SALE_MONTHLY_PRICE, SALE_ANNUAL_MONTHLY_PRICE } from "./../../../pages/premium/paymentsConfig"

type Props = {
  onMonthlyPress: VoidFunction
  onAnnualPress: VoidFunction
}

export const PremiumProducts = ({ onMonthlyPress, onAnnualPress }: Props) => {
  const postHog = usePostHog()
  const featureFlag = postHog?.getFeatureFlag('show-sale')

  const monthlyPrice = featureFlag === 'show' ? SALE_MONTHLY_PRICE : MONTHLY_PRICE;
  const monthlyAnnualPrice = featureFlag === 'show' ? SALE_ANNUAL_MONTHLY_PRICE : ANNUAL_MONTHLY_PRICE;
  const salePercentage = featureFlag === 'show' ? SALE_PERCENTAGE : 0;

  return (
    <div className="flex space-x-2 p-4">
      <>
        <div className="flex-1">
          <SubscriptionPlan
            popular
            title="Annual"
            price={monthlyAnnualPrice}
            salePercentage={salePercentage}
            additionalDescription="Save 64%"
            onPress={onAnnualPress} />
        </div>
        <div className="flex-1">
          <SubscriptionPlan
            title="Monthly"
            price={monthlyPrice}
            salePercentage={salePercentage}
            onPress={onMonthlyPress} />
        </div>
      </>
    </div >
  )
}