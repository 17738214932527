import { Back } from "./Back"
import { CrossIcon } from "./CrossIcon"

type Props = {
  onBack?: VoidFunction
  onClose?: VoidFunction
  showLogo?: boolean
  title?: string
  containerClass?: string
}

export const YourMoveHeader = ({ onBack, onClose = undefined, showLogo = false, title, containerClass }: Props) => {

  return (
    <div className={`relative flex items-center p-4 py-2 h-10 ${containerClass}`}>
      {onBack && (
        <div className="absolute left-4" onClick={onBack}>
          <Back containerClass="w-6 h-10" color="stroke-[#999999]" />
        </div>
      )}
      <div className="mx-auto font-semibold text-black">
        {title ? (
          title
        ) : showLogo ? (
          <>
            YourMove
            <span className="bg-brand-primary text-white px-2 py-1 ml-1 font-semibold rounded-[5px]">
              Premium
            </span>
          </>
        ) : null}
      </div>
      {onClose && (
        <div className="absolute right-4" onClick={onClose}>
          <CrossIcon color="black" />
        </div>
      )}
    </div>
  )
}