import { useEffect, useState } from "react";
import { LearnMoreModal } from "../../modals/LearnMoreModal";
import { useWizardStore } from "../../../stores/wizard";
import { PlanType, ProductType } from "../../../constants/payments";
import { EventParams, logEvent, useLogEvent } from "../../../analytics";
import { PremiumProductsCarousel } from "./PremiumProductsCarousel";
import { SaleBanner } from "./SaleBanner";
import { PremiumProducts } from "./PremiumProducts";
import { ANNUAL_PRICE, MONTHLY_PRICE, SALE_PERCENTAGE } from "../../../pages/premium/paymentsConfig";
import { useAuthStore } from "../../../stores/auth";
import { hasUserPaid } from "../../../queries";
import { Loading } from "../../Loading";
import { usePaymentStore } from "../../../stores/payment";
import { usePostHog } from 'posthog-js/react'


export const ProfileWriterPaywall = () => {
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const [loading, setLoading] = useState(true)

  const { setProfileWriterWizardComplete, email } = useWizardStore()
  const { setOpenPaymentModal, setPlanType, setProduct } = usePaymentStore()
  const { isSubscribed } = useAuthStore();

  const postHog = usePostHog()
  const featureFlag = postHog?.getFeatureFlag('show-sale')
  const salePercentage = featureFlag === 'show' ? SALE_PERCENTAGE : 0;

  useLogEvent('paywall', 'profile_writer')

  useEffect(() => {
    if (isSubscribed) setProfileWriterWizardComplete(true)
    else {
      hasUserPaid(email, [ProductType.ProfileWriter]).then((response) => {
        // if any products bought by the user are in the requiredProductsToSkipPaywall
        if (
          response.data.purchasedProducts.some((product: ProductType) =>
            [ProductType.ProfileWriter].includes(product)
          )
        ) {
          // then we can skip the paywall
          setProfileWriterWizardComplete(true)
        } else {
          // else, show plans as they haven't paid
          setLoading(false);
        }
      });
    }
  }, [isSubscribed, hasUserPaid, email])

  const onMonthlyPress = () => {
    setPlanType(PlanType.Monthly)
    setProduct("profile_writer")
    setOpenPaymentModal(true)

    const params: EventParams = {
      amount: `${MONTHLY_PRICE}`,
      payment_type: 'monthly',
    }
    const showSaleFlag = postHog?.getFeatureFlag('show-sale');
    if (showSaleFlag !== undefined) {
      params['show_sale'] = showSaleFlag;
    }
    logEvent('purchase_click', "profile_writer", params, 'payment')
    postHog?.capture('purchase_click', params)
  }
  const onAnnualPress = () => {
    setPlanType(PlanType.Yearly)
    setProduct("profile_writer")
    setOpenPaymentModal(true)

    const params: EventParams = {
      amount: `${ANNUAL_PRICE}`,
      payment_type: 'annual'
    }
    const showSaleFlag = postHog?.getFeatureFlag('show-sale');
    if (showSaleFlag !== undefined) {
      params['show_sale'] = showSaleFlag;
    }
    logEvent('purchase_click', "profile_writer", params, 'payment')
    postHog?.capture('purchase_click', params)
  }

  return loading ? <Loading /> : (
    <>
      <LearnMoreModal
        open={learnMoreModalOpen}
        setOpen={setLearnMoreModalOpen}
      />
      <div className="mt-8">
        <div className="-mt-14">
          <div className="-mx-6 pb-2 min-h-[80vh] h-full flex flex-col">
            {salePercentage ? <SaleBanner /> : null}
            <div className="bg-main py-2">
              <p className="text-xl font-semibold text-black text-center">Unlock all features</p>
            </div>
            <PremiumProductsCarousel initialKey="profile_writer" />
            <div className="relative w-screen left-1/2 right-1/2 -mx-[50vw] bg-brand-primary flex-grow">
              <div className="mx-auto max-w-xl h-full flex flex-col justify-between">
                <PremiumProducts onMonthlyPress={onMonthlyPress} onAnnualPress={onAnnualPress} />
                <h3
                  className="text-center cursor-pointer text-lg text-white hover:text-zinc-600 hover:underline mt-2 mb-6"
                  onClick={() => {
                    logEvent('no_thanks', 'profile_writer', {}, 'payment');
                    window.scrollTo(0, 0);
                    setProfileWriterWizardComplete(true);
                  }}
                >
                  No, thanks
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
