import React, { useEffect, useState } from 'react';
import { useProfileStore } from "../../stores/profile";
import { useWizardStore } from "../../stores/wizard";
import { WizardStepType } from "../../models/wizard";
import { FamewallReviews } from "../../pages/FamewallReviews";
import { logEvent, useLogEvent } from "../../analytics";
import { usePaymentStore } from "../../stores/payment";
import { usePostHog } from 'posthog-js/react'
import { Back } from "../../components/Back"
import { FullProfileReview } from "../../components/profile-reviewer/FullProfileReview"
import { PartialProfileReview } from "../../components/profile-reviewer/PartialProfileReview"
import { AIPhotosModal } from "../../components/ai-photos/AIPhotosModal"

type Props = {
  hasPaid: boolean
  setHasPaid: (hasPaid: boolean) => void
  onBackPress?: () => void
}

export const ProfileReviewWelcome = ({ hasPaid, setHasPaid, onBackPress = undefined }: Props) => {
  const postHog = usePostHog()
  const [isWelcomePage, setIsWelcomePage] = useState(false);
  const { welcomeReviewedProfile } = useProfileStore();
  const { setWelcomeWizardComplete, setWelcomeStep } = useWizardStore();
  const { setOpenPaymentModal, setProduct } = usePaymentStore();

  const showDetailOnboarding = postHog.getFeatureFlag('show-detailed-onboarding') === 'show'

  useLogEvent('results', 'profile_review');

  useEffect(() => {
    setIsWelcomePage(window.location.pathname === '/welcome');
  }, []);

  useEffect(() => {
    if (isWelcomePage) postHog?.capture('review_results')
  }, [isWelcomePage])

  const goBack = () => {
    if (onBackPress) {
      onBackPress();
    } else {
      window.history.back();
    }
    setWelcomeWizardComplete(false);
    setWelcomeStep(WizardStepType.UPLOAD_PHOTO);
  }

  const onUnlockFullReviewClick = () => {
    logEvent('purchase_unlock', 'profile_review', {}, 'payment');
    setProduct("profile_review");
    setOpenPaymentModal(true);
    postHog?.capture('review_payment_unlock')
  }

  return (
    <>
      <div className="mx-auto max-w-xl">
        <div className="flex flex-col flex-1">
          <div className="mt-8">
            {!showDetailOnboarding && <Back onClick={goBack} />}
            <h1 className="text-2xl font-bold mt-4 mb-2">Your review is ready</h1>
            <div className="border-2 border-black rounded-2xl shadow-lg">
              <div className="flex flex-1 rounded-t-2xl bg-brand-alt-light bg-opacity-100">
                <div className="py-3 px-2 items-center flex-1">
                  <p className="font-semibold ml-2 text-sm">Current Rating</p>
                  <p className="font-bold ml-2 text-2xl">{`${welcomeReviewedProfile?.currentRating ?? 0}/10`}</p>
                </div>
                <div className="py-3 px-2 mr-2 items-center">
                  <p className="font-semibold ml-2 text-sm">Rating after improvement</p>
                  <p className="font-bold ml-2 text-2xl text-brand-primary">{`${welcomeReviewedProfile?.possibleRating ?? 0}/10`}</p>
                </div>
              </div>
              <div className="px-4 py-4 bg-white rounded-b-2xl">
                {hasPaid ?
                  <FullProfileReview review={welcomeReviewedProfile} /> :
                  <PartialProfileReview summary={welcomeReviewedProfile?.summary ?? ""} onUnlockFullReviewClick={onUnlockFullReviewClick} />
                }
              </div>
            </div>
            <div className="mt-8 mb-10">
              <AIPhotosModal />
            </div>
          </div>
        </div>
      </div>
      <FamewallReviews
        containerStyle='-mx-4 px-4'
        title='See what our users say'
      />
    </>
  );
};
