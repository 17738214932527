import { Formik, Form } from 'formik';

import * as Yup from 'yup';
import toast, { LoaderIcon } from 'react-hot-toast';
import { Field } from './../components/Field'
import { PrimaryButton } from './../components/PrimaryButton'
import { useWizardStore } from '../stores/wizard';
import { requestAccountDeletion } from '../queries';
import { YourMoveHeader } from '../components/YourMoveHeader';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../components/LoadingSpinner';


const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const AccountDeletionRequest = () => {
  const navigate = useNavigate()
  const { email } = useWizardStore()
  const handleSubmit = async () => {
    // Here you would typically send a request to your backend API
    // For demonstration, we'll just simulate a successful submission
    try {
      await requestAccountDeletion(email);
      toast.success(`Deletion request sent!`);
    } catch (error) {
      toast.error('Request failed')
    }
  };

  const onBack = () => navigate("/user-settings")

  return (
    <div className="mx-auto max-w-xl pb-8">
      <div className="flex flex-col flex-1">
        <YourMoveHeader containerClass='mb-1 mt-1' onBack={onBack} />
        <div className="w-full">
          <div className="flex items-center text-center justify-center px-10 mb-10">
            <h1 className="text-2xl font-bold">
              Request Account Deletion
            </h1>
          </div>
          <div className="mx-4">
            <Formik
              initialValues={{ email: email }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid }) => (
                <Form className="space-y-4">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                  />
                  <PrimaryButton
                    type='submit'
                    containerClass="mt-4 mb-4 py-4 w-full"
                    title={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting || !isValid}
                    rightIcon={isSubmitting && <LoadingSpinner />}
                  />
                </Form>
              )}
            </Formik>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletionRequest;