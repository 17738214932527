import { useState } from "react"
import { SideNavListItem } from "../components/nav/SideNavListItem";
import { FeedbackModal } from "../components/modals/FeedbackModal";
import { useNavigate } from "react-router-dom";

const STRIPE_UNSUBSCRIBE_LINK = "https://billing.stripe.com/p/login/9AQ14Sc4N0EX7E48ww"

export const UserSettings = () => {
  const navigate = useNavigate()
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  return (
    <>
      <FeedbackModal open={feedbackModalOpen} setOpen={setFeedbackModalOpen} />
      <div className="mx-auto max-w-xl pb-8">
        <div className="flex flex-col flex-1">
          <div className="w-full mt-8">
            <div className="flex items-center text-center justify-center mt-4 px-10 mb-10">
              <h1 className="text-2xl font-bold">
                User Settings
              </h1>
            </div>
            <ul className="mx-4">
              <SideNavListItem key="0" containerStyle="mt-auto mb-4" title="Contact Us" onNavItemClick={() => {
                setFeedbackModalOpen(true)
              }} />
              <SideNavListItem key="1" containerStyle="mt-auto mb-4" title="Manage Subscription" target="_blank" href={STRIPE_UNSUBSCRIBE_LINK} onNavItemClick={() => {
              }} />
              <SideNavListItem key="1" containerStyle="mt-auto mb-4" title="Request Account Deletion" target="_blank" onNavItemClick={() => {
                navigate("/request-delete")
              }} />
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}