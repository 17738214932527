import { create } from "zustand";
import { ReviewType, WizardStepType } from "../models/wizard";
import { checkLocalStorageForInitialStep, checkLocalStorageForInitialStepResults, getBooleanFromLocalStorage, checkLocalStorageForFiles, checkLocalStorageForKey } from "./utils";

interface WizardStore {
  // For profile writer
  profileWriterStep: WizardStepType;
  setProfileWriterStep: (step: WizardStepType) => void;
  profileWriterStepResults: Record<string, string>;
  setProfileWriterStepResult: (stepType: string, result: string) => void;
  profileWriterWizardComplete: boolean;
  setProfileWriterWizardComplete: (wizardComplete: boolean) => void;

  // For profile reviewer
  profileReviewerStep: WizardStepType;
  setProfileReviewerStep: (step: WizardStepType) => void;
  profileReviewerStepResults: Record<string, string>;
  setProfileReviewerStepResult: (stepType: string, result: string) => void;
  profileReviewerWizardComplete: boolean;
  setProfileReviewerWizardComplete: (wizardComplete: boolean) => void;
  filesUploading: boolean;
  setFilesUploading: (filesUploading: boolean) => void;
  profileReviewerFiles: Array<string>;
  setProfileReviewerFiles: (files: Array<string>) => void;
  reviewType: ReviewType;
  setReviewType: (reviewType: ReviewType) => void;

  // Generic email field
  email: string;
  setEmail: (email: string) => void;

  // For ai photo review
  photoReviewStep: WizardStepType;
  setPhotoReviewStep: (step: WizardStepType) => void;
  photoReviewStepResults: Record<string, string>;
  setPhotoReviewStepResults: (stepType: string, result: string) => void;
  photoReviewWizardComplete: boolean;
  setPhotoReviewWizardComplete: (wizardComplete: boolean) => void;

  profileReviewStarted: boolean;
  setProfileReviewStarted: (profileReviewStarted: boolean) => void;

  welcomeStep: WizardStepType;
  setWelcomeStep: (welcomeStep: WizardStepType) => void;
  welcomeStepResults: Record<string, string>;
  setWelcomeStepResults: (stepType: string, result: string) => void;
  welcomeWizardComplete: boolean;
  setWelcomeWizardComplete: (welcomeWizardComplete: boolean) => void;
  welcomeReviewerFiles: Array<string>;
  setWelcomeReviewerFiles: (files: Array<string>) => void;
  welcomeFilesUploading: boolean;
  setWelcomeFilesUploading: (filesUploading: boolean) => void;
  welcomeReviewStarted: boolean;
  setWelcomeReviewStarted: (welcomeReviewStarted: boolean) => void;

  demoStep: WizardStepType;
  setDemoStep: (demoStep: WizardStepType) => void;
  demoStepResults: Record<string, string>;
  setDemoStepResults: (stepType: string, result: string) => void;
  demoWizardComplete: boolean;
  setDemoWizardComplete: (demoWizardComplete: boolean) => void;
  demoReviewerFiles: Array<string>;
  setDemoReviewerFiles: (files: Array<string>) => void;
  demoFilesUploading: boolean;
  setDemoFilesUploading: (filesUploading: boolean) => void;
  demoReviewStarted: boolean;
  setDemoReviewStarted: (demoReviewStarted: boolean) => void;
}

export const useWizardStore = create<WizardStore>((set) => ({
  // For profile writer
  profileWriterStep: checkLocalStorageForInitialStep(
    "profileWriter"
  ) as WizardStepType,
  setProfileWriterStep: (step: WizardStepType) =>
    set({ profileWriterStep: step }),
  profileWriterStepResults:
    checkLocalStorageForInitialStepResults("profileWriter"),
  setProfileWriterStepResult: (stepType, result) => {
    set((state) => ({
      profileWriterStepResults: {
        ...state.profileWriterStepResults,
        [stepType]: result,
      },
    }));
  },
  profileWriterWizardComplete: false,
  setProfileWriterWizardComplete: (wizardComplete: boolean) =>
    set({ profileWriterWizardComplete: wizardComplete }),

  // Profile Reviewer
  profileReviewerStep: checkLocalStorageForInitialStep(
    "profileReviewer"
  ) as WizardStepType,
  setProfileReviewerStep: (step: WizardStepType) =>
    set({ profileReviewerStep: step }),
  profileReviewerStepResults:
    checkLocalStorageForInitialStepResults("profileReviewer"),
  setProfileReviewerStepResult: (stepType, result) => {
    set((state) => ({
      profileReviewerStepResults: {
        ...state.profileReviewerStepResults,
        [stepType]: result,
      },
    }));
  },
  profileReviewerWizardComplete: getBooleanFromLocalStorage('profileReviewerWizardComplete'),
  setProfileReviewerWizardComplete: (wizardComplete: boolean) => {
    set({ profileReviewerWizardComplete: wizardComplete })
    localStorage.setItem('profileReviewerWizardComplete', wizardComplete.toString())
  },
  profileReviewerFiles: checkLocalStorageForFiles("profileReviewer"),
  setProfileReviewerFiles: (files: Array<string>) => {
    set({ profileReviewerFiles: files })
    localStorage.setItem('profileReviewerFiles', JSON.stringify(files))
  },
  reviewType: ReviewType.Profile,
  setReviewType: (reviewType: ReviewType) =>
    set({ reviewType }),
  email: checkLocalStorageForKey("email"),
  setEmail: (email: string) => {
    set({ email });
    localStorage.setItem('email', email)
  },
  filesUploading: false,
  setFilesUploading: (filesUploading: boolean) =>
    set({ filesUploading: filesUploading }),

  // AI Photo review
  photoReviewStep: checkLocalStorageForInitialStep(
    "photoReview"
  ) as WizardStepType,
  setPhotoReviewStep: (step: WizardStepType) =>
    set({ photoReviewStep: step }),
  photoReviewStepResults:
    checkLocalStorageForInitialStepResults("photoReview"),
  setPhotoReviewStepResults: (stepType, result) => {
    set((state) => ({
      photoReviewStepResults: {
        ...state.photoReviewStepResults,
        [stepType]: result,
      },
    }));
  },
  photoReviewWizardComplete: false,
  setPhotoReviewWizardComplete: (wizardComplete: boolean) =>
    set({ photoReviewWizardComplete: wizardComplete }),

  profileReviewStarted: false,
  setProfileReviewStarted: (profileReviewStarted: boolean) =>
    set({ profileReviewStarted: profileReviewStarted }),

  welcomeStep: checkLocalStorageForInitialStep(
    "welcome"
  ) as WizardStepType,
  setWelcomeStep: (step: WizardStepType) =>
    set({ welcomeStep: step }),
  welcomeStepResults:
    checkLocalStorageForInitialStepResults("welcome"),
  setWelcomeStepResults: (stepType, result) => {
    set((state) => ({
      welcomeStepResults: {
        ...state.welcomeStepResults,
        [stepType]: result,
      },
    }));
  },
  welcomeReviewerFiles: checkLocalStorageForInitialStepResults(
    "welcome"
  )
    ? JSON.parse(
      checkLocalStorageForInitialStepResults("welcome").uploadPhoto
        ? checkLocalStorageForInitialStepResults("welcome")
          .uploadPhoto
        : "[]"
    )
    : [],
  setWelcomeReviewerFiles: (files: Array<string>) =>
    set({ welcomeReviewerFiles: files }),
  welcomeFilesUploading: false,
  setWelcomeFilesUploading: (filesUploading: boolean) =>
    set({ welcomeFilesUploading: filesUploading }),
  welcomeWizardComplete: false,
  setWelcomeWizardComplete: (wizardComplete: boolean) =>
    set({ welcomeWizardComplete: wizardComplete }),
  welcomeReviewStarted: false,
  setWelcomeReviewStarted: (reviewStarted: boolean) =>
    set({ welcomeReviewStarted: reviewStarted }),

  demoStep: checkLocalStorageForInitialStep(
    "demo"
  ) as WizardStepType,
  setDemoStep: (step: WizardStepType) =>
    set({ demoStep: step }),
  demoStepResults:
    checkLocalStorageForInitialStepResults("demo"),
  setDemoStepResults: (stepType, result) => {
    set((state) => ({
      demoStepResults: {
        ...state.demoStepResults,
        [stepType]: result,
      },
    }));
  },
  demoReviewerFiles: checkLocalStorageForFiles("demoReviewer"),
  setDemoReviewerFiles: (files: Array<string>) => {
    localStorage.setItem('demoReviewerFiles', JSON.stringify(files))
    set({ demoReviewerFiles: files })
  },
  demoFilesUploading: false,
  setDemoFilesUploading: (filesUploading: boolean) =>
    set({ demoFilesUploading: filesUploading }),
  demoWizardComplete: false,
  setDemoWizardComplete: (wizardComplete: boolean) =>
    set({ demoWizardComplete: wizardComplete }),
  demoReviewStarted: false,
  setDemoReviewStarted: (reviewStarted: boolean) =>
    set({ demoReviewStarted: reviewStarted }),
}));

