import { ReactNode } from 'react';

type Props = {
  title: string;
  onClick?: VoidFunction;
  containerClass?: string;
  titleClass?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const PrimaryButton = ({
  title,
  onClick,
  containerClass = '',
  titleClass = '',
  leftIcon,
  rightIcon,
  disabled = false,
  type = 'button'
}: Props) => (
  <button
    className={`flex items-center justify-center bg-brand-primary text-white 
      h-12 rounded-lg font-semibold ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-brand-primary-dark'
      } ${containerClass}`}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {leftIcon && <span className="mr-2">{leftIcon}</span>}
    <p className={`text-lg ${titleClass}`}>{title}</p>
    {rightIcon && <span className="ml-2">{rightIcon}</span>}
  </button>
);